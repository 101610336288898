<template>
    <footer class="text-center">
        <small>Copyright &copy; <span id="year">{{ year }}</span> Karmi Ek Khoj. All rights reserved.</small>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                year: new Date().getFullYear()
            }
        }
    }
</script>