<template>
  <div class="navbar">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img
          class="img-fluid"
          src="@/assets/images/logo-light.png"
          alt="Karmi Ek Khoj: A Book by Kalyan Singh Karmiyal"
        />
      </a>
        <ul class="navbar-nav ms-auto">
            <li class="nav-item me-5">
                <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/book" class="nav-link">The Book</router-link>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router'

export default {
  components: {
    RouterLink
  }
}
</script>
