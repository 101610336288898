<template>
  <div id="app">
    <PageHeader />

    <router-view />
    
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import PageFooter from "@/components/layouts/PageFooter.vue";

export default {
  components: {
    PageHeader,
    PageFooter
  },
}
</script>
