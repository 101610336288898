<template>
  <div>
    <HeroSection />
    
    <section class="container mt-5 author">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2 class="heading mb-5">About The Author</h2>
          <h3>Kalyan Singh Karmiyal</h3>
          <p>S/O Smt. Tulsi Devi and Late. Shri Praveen Singh Karmiyal</p>
          <p><strong>DOB:</strong> 01 July 1970</p>
          <p>
            <strong>Address:</strong> 
            Village & PO - Karmi<br>
            District - Bageshwar (Uttarakhand)
          </p>
          <p><strong>Mobile:</strong> 9412910000</p>
          <p><strong>Email:</strong> kalyansinghkarmiyal@gmail.com</p>
        </div>
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/images/profile-pic.png" alt="Kalyan Singh Karmyal">
        </div>
      </div>
    </section>

    <div class="other-book-details py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/images/3-book-mockup.png" alt="Karmi Ek Khoj Book Mockups">
          </div>
          <div class="col-md-6 ps-md-5">
            <h2 class="heading mb-5">Book Details</h2>
            <strong class="text-body-secondary">Co-Author:</strong>
            <h3 class="fs-4">Trilok Singh Soragi</h3>
            <p>
              S/O Shri Phateh Singh Soragi
              <strong class="d-block mt-3">Co-Principal</strong>
              Pinewood Academy, Kapkot, Bageshwar (Uttarakhand)
            </p>
            <p><strong>First Edition:</strong> July 2023</p>
            <p>
              <strong>Designing & Computerization:</strong> 
              Sunil Singh Parihar<br>
              Devbhoomi Advertiser, Bageshwar (Uttarakhand)
            </p>
            <p>
              <strong>Publisher:</strong> 
              P. D. Printers<br>
              Kaladhungi Road, Haldwani (Uttarakhand)<br>
              Mobile: 9411540130
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";

export default {
  components: {
    HeroSection,
  },

  mounted() {
    if (document.body.classList.contains('book')) {
      document.body.classList.remove('book');
    }
  }
};
</script>
